import { fromJS, Set, Map } from 'immutable';

import {
    ADD_PORT_FILTER,
    REMOVE_PORT_FILTER,
    CLEAR_PORT_FILTERS,
    GET_CHARITY_START,
    GET_CHARITY_SUCCESS,
    GET_CHARITY_ERROR,
    SET_PORT_FILTER,
} from './charity.action';

const initialState = fromJS({
    filters: new Set(),
    charity: {},
    charityInit: false,
    charityLoading: false,
});

export const charity = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PORT_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(
                    f => f.type === filter.type && (filter.single || f.value === filter.value)
                );
                if (thisFilter.length > 0) {
                    let newFilters = filters.delete(thisFilter[0]);
                    if (filter.single) {
                        newFilters = newFilters.add(filter);
                    }
                    ctx.set('filters', newFilters);
                } else {
                    ctx.set('filters', filters.add(filter));
                }
            });
        case REMOVE_PORT_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(
                    f => f.type === filter.type && (filter.single || f.value === filter.value)
                );
                if (thisFilter.length > 0) {
                    let newFilters = filters.delete(thisFilter[0]);
                    if (filter.single) {
                        newFilters = newFilters.add(filter);
                    }
                    ctx.set('filters', newFilters);
                }
            });
        case SET_PORT_FILTER:
            return state.withMutations(ctx => {
                const { filters } = action.payload;
                ctx.set('filters', new Set(filters));
            });
        case CLEAR_PORT_FILTERS:
            return state.withMutations(ctx => {
                const { type } = action.payload;
                const filters = ctx.get('filters');
                if (type === '') {
                    ctx.set('filters', new Set());
                } else {
                    const newFilters = Array.from(filters).filter(f => f.type !== type);
                    ctx.set('filters', new Set(newFilters));
                }
            });
        case GET_CHARITY_START:
            return state.withMutations(ctx => {
                ctx.set('charityInit', true);
                ctx.set('charityLoading', true);
            });
        case GET_CHARITY_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('charityLoading', false);
                if (action.payload.append) {
                    const news = ctx.get('charity').toJS();
                    const data = news.data || [];
                    action.payload.data.data = [...data, ...action.payload.data.data];
                    ctx.set('charity', new Map(action.payload.data));
                } else {
                    ctx.set('charity', new Map(action.payload.data));
                }
            });
        case GET_CHARITY_ERROR:
            return state.withMutations(ctx => {
                ctx.set('charityLoading', true);
                ctx.set('charity', new Map({}));
            });
        default:
            return state;
    }
};
