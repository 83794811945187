import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'normalize.css';
import Head from 'next/head';
// import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useStore } from 'react-redux';

import TagManager from 'react-gtm-module';
//* Redux Store
import { wrapper } from '../redux/store';
//* Style
import '../styles/global.scss';
import '../styles/fonts.scss';
import '../styles/icon.scss';
import '../styles/form.scss';

const MyApp = ({ Component, pageProps }) => {
    const store = useStore(state => state);

    useEffect(() => {
        if (process.env.GTM_ID) {
            TagManager.initialize({ gtmId: process.env.GTM_ID });
        }
    }, []);

    return (
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={store.__persistor}> */}
            <Head>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1' />
            </Head>
            <Component {...pageProps} />
            {/* </PersistGate> */}
        </Provider>
    );
};

MyApp.getInitialProps = async ({ Component, ctx }) => {
    const { res } = ctx;
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    if (res) {
        try {
            res.setHeader('Cache-Control', 's-maxage=86400, stale-while-revalidate');
        } catch (e) {

        }
    }

    return { pageProps: pageProps };
};

MyApp.propTypes = {
    Component: PropTypes.func,
    pageProps: PropTypes.object,
};

MyApp.defaultProps = {};

// withRedux wrapper that passes the store to the App Component
export default wrapper.withRedux(MyApp);
